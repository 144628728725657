import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import { Seo } from "../../components/seo";
import FAQAccord from "../../components/GFlush";
import Layout from "../../components/Layout";
import Counter from "../../components/Counter";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from "../../components/Hero";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import ImgContent from "../../components/GSTTemp/ImgContent";
import ContentForm from "../../components/GSTTemp/ContentForm";
import Cta from "../../components/Cta";
import SliderContent from "../../components/GSTTemp/SliderContent";
import ContentImg from "../../components/GSTTemp/ContentImg";
import Package from "../../components/GSTTemp/Package";

export default function Payroll() {
  /* Slider */
  // const PayrollSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 30,
  //     background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
  //     header: `Online Payroll Management Service`,
  //     content: `Advisory Service for Online Payroll Management Service`,
  //     image: "/imgs/business/checklist-nid.png",
  //     alt_tag:
  //       "Best Online Advisory Service for Online Payroll Management Service in Hosur",
  //   },
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 30,
  //     background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
  //     header: `Online Advisory Service for Payroll Management Service`,
  //     content: `Best Advisory Service for Payroll Management Service Platform`,
  //     image: "/imgs/business/sliders/pvt-slider.png",
  //     alt_tag:
  //       "Best Online Advisory Service for Payroll Management Service  near Bangalore",
  //   },

  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 40,
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `Advisory Service for Payroll Management Service in Hosur`,
  //     content: `One the top most success rated Advisory Service for Payroll Management in Hosur`,
  //     image: "/imgs/business/sliders/nidhi-slider.png",
  //     alt_tag:
  //       "Best Online Advisory Service for Payroll Management Service in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Payroll Management`,
    buyBtnLink: `#pricing-buy`,
    price: `4999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };
  /* About Data With Form*/
  const payrollAboutData = {
    header: `Payroll Management Service`,
    sub_title: `File  personal and business related online payroll
      management service.`,
    content_paragraph: [
      // `While some businesses choose to handle payroll in-house with
      //   manual accounting or on their own with payroll software,
      //   others have learned that the expertise of a professional
      //   payroll provider is preferable.`,

      `According to a recent survey, one-quarter of payroll users
        are concerned about staying up to date on tax laws and
        regulations, more than 20% are concerned about the time it
        takes to manually process payroll, and more than 15% are
        concerned about tasks like managing taxes, withholdings, and
        other expenses for employees in different locations.`,
    ],
  };

  /* Scroll Nav Data */
  const AccPAyScrollId = [
    {
      id: `#methods`,
      heading: `Methods`,
    },
    {
      id: `#payroll-process`,
      heading: `Payroll Process`,
    },
    {
      id: `#payroll-tax`,
      heading: `Payroll Taxes`,
    },
    {
      id: `#checklist`,
      heading: `Checklist`,
    },
  ];

  /* Img Content Component Data */
  const PayrollIcData = {
    id: 'methods',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'Various Payroll Management Service Methods in India',
    image: '/imgs/business/llp.png',
    alt_tag: 'Payroll Management Service Methods',
    paragraph: `In general, companies use three methods to manage
      their payroll, which are explained more below:`,
    points: [
      {
        head: `Payroll Management Software`,
        content: `There are numerous reliable payroll management
          software to choose from. Such software solutions
          typically offer a template for entering personnel
          information and other critical input criteria. The
          best payroll software for day-to-day operations is
          influenced by the firm's size and structure.`,
        icon: true,
      },
      {
        head: `Excel`,
        content: `This method requires constructing an Excel payroll
          administration template that allows for computations
          and the use of mathematical formulae. Start-ups and
          small firms are the most likely to employ this method.
          This method has the disadvantage of being difficult to
          regulate and prone to errors.`,
        icon: true,
      },
      {
        head: `Outsourcing`,
        content: `Payroll administration can be outsourced to a
          third-party company if a company has the requisite
          resources. For each payment cycle, the company
          produces and transmits an updated payment schedule to
          the payroll consultant for processing. Although
          outsourcing payroll administration simplifies the
          company's job, the need for confidentiality may
          prevent it from doing so.`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const AccPayCfData = {
    id: 'payroll-process',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'Payroll process and HR management',
    content: '',
    body: [
      {
        head: `Payroll`,
        points: `Payroll processing is an important business procedure
          that involves determining an employee's 'net pay'
          after deducting all applicable taxes and deductions.
          To guarantee that the payroll process operates well,
          the payroll administrator must organize it step by
          step.`,
        icon: true,
      },
      {
        head: `HR Management`,
        points: `Human resource management (HRM or HR) is a strategic
          approach to managing people in a company or
          organization so that they help the company gain a
          competitive advantage. Its primary goal would be to
          optimize human resource performance in support of a
          company's strategic goals and objectives.`,
        icon: true,
      },
      {
        head: `Calculating Payroll`,
        points: `Net Salary = Gross Salary - All Deductions is a
          formula that can be used to calculate payroll.`,
        icon: true,
      },
      {
        points: `In this case, the company can calculate the net
          compensation to be given to the employee by
          subtracting the deductions from the gross salary,
          which is the sum of the benefits and the basic wage.`,
        icon: true,
      },
      {
        points: `So, the Gross Salary is the sum of the Basic Salary +
          HRA + All Allowances + Arrears (if any) +
          Reimbursements (if any) + Bonus (if any)`,
        icon: true,
      },
      {
        points: `And the deductions are as follows: professional tax +
          insurance + public provident fund + income tax + leave
          take adjustment + loan repayments (if any)`,
        icon: true,
      },
      {
        head: `Payment Processing In Accounts Payable`,
        points: `The credit period must be tightly controlled by the
          business because it directly affects its revenue. On
          the one hand, paying early provides the firm with
          additional cutoff points, saving money, while on the
          other side, paying on time again enables the business
          to accept a full credit term.`,
        icon: true,
      },
    ],
  };

  /* ImgSlider Data */
  const PayrollImages = [
    '/imgs/registration/dsc/dsc-imgslider.png',
    '/imgs/registration/dsc/dsc-img-slider.png',
    // "/imgs/registration/dsc/dsc-img-sliders.jpg",
  ];

  /* SliderContent Component Data */
  const PayrollSCData = {
    id: 'payroll-tax',
    background: '',
    mt_div: '',
    header: `Payroll taxes and returns are prepared and submitted
    to the appropriate government agencies.`,
    content: [
      {
        points: `Payroll tax obligations must still be met each quarter
        and at the end of the fiscal year. A quarterly payroll
        tax form must be carefully calculated and submitted by
        business owners or appointed administrators. `,
        icon: true,
      },
      {
        points: `More than half of manual payroll users consider tax
        filing and payment to be a critical component of an
        outsourced payroll system.`,
        icon: true,
      },
    ],
    images: PayrollImages,
  };
  /*  Content Img Component Data */
  const PayrollCiData = {
    id: 'checklist',
    background: 'main-content',
    mt_div: 'mt-3',
    mt_img: 'mt-5',
    header: `Checklist for Choosing a Reliable Payroll Management
    Service in India`,
    paragraph: `When a business outsources payroll administration, the
    main goal is to ensure that their human resources or
    employees are properly compensated. It is critical
    that you have a checklist on hand to help you pick who
    you should rely on to outsource your payroll
    administration service in India.`,
    points: [
      {
        content: `Benefit administration.`,
        icon: true,
      },
      {
        content: `Employees as well as interns.`,
        icon: true,
      },
      {
        content: `Payroll scheduling and tracking.`,
        icon: true,
      },
      {
        content: `Details on bonuses and deductions.`,
        icon: true,
      },

      {
        content: `Printing checks and creating payslips (Automated).`,
        icon: true,
      },

      {
        content: `Payroll dashboard and payment choices, including direct deposit.`,
        icon: true,
      },
      {
        content: `Payroll reports are generated weekly, monthly, interim,
        annual basis.`,
        icon: true,
      },

      {
        content: `Sections for contractual, salaried, full-time, part-time,
        temporary staff.`,
        icon: true,
      },
    ],
    image: '/imgs/registration/ngo/fcra-registration.png',
    alt_tag: 'TODAYFILINGS Role in Account Payable Service',
  };
  var FAQ_data = [
    {
      header: 'What is the payroll management?',
      body: [
        {
          content: `Compensation for services given is an administrative task. It also
          keeps track of employee gross earnings, payroll deductions, and
          net pay, as well as the employer's payroll tax liability.`,
          icon: false,
        },
      ],
    },

    {
      header: 'Various methods of payroll management service?',
      body: [
        {
          content: `Payroll management software`,
          icon: true,
        },
        {
          content: `Outsourcing`,
          icon: true,
        },
        {
          content: `Excel`,
          icon: true,
        },
      ],
    },
    {
      header: 'Why TODAYFILINGS Payroll management service?',
      body: [
        {
          content: `The information provided above should serve as a wake-up call to
          any company considering outsourcing its payroll computation and
          remittance so that they can reap several benefits while avoiding
          many of the challenges that existed previously.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What is included in Payroll activities?',
      body: [
        {
          content: `Payroll activities include attendance data for a particular month to capture the 
          pays and the accurate attendance of the employees, details of new employees and employees left, 
          the salary structure of new joiners and their bank account details, if there is any advance or 
          incentive to be paid and if there are any special changes that are to be made to the salary structure.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Is payroll part of HR and Accounting?',
      body: [
        {
          content: `Payroll comes under both the HR and the finance departments.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Who prepares Payroll?',
      body: [
        {
          content: `The Human resource office prepares the payroll since they hold the records of the 
          attendance and the overtime that is rendered by the employees, the accounts department prepares 
          the payment and is subjected to approval from the head of the Agency or the representative.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Payroll Management Service'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Online E-filing'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={PayrollSlider} /> */}
          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={payrollAboutData} />

          <Counter />

          <ScrollNav scroll_data={AccPAyScrollId} />

          <ImgContent item={PayrollIcData} />

          <ContentForm CFSection_data={AccPayCfData} />

          <SliderContent ScSection_data={PayrollSCData} />

          <ContentImg CISection_data={PayrollCiData} />

          <Cta />
          <Guidance />

          <FAQAccord items={FAQ_data} />

          <Testimonial />
        </main>
      </Layout>
    </div>
  );
}
